import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './scss/style.scss';
import { AuthProvider } from './views/pages/AuthContext';
import { Analytics } from '@vercel/analytics/react';
import Hotjar from '@hotjar/browser';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  componentDidMount() {
    // Initialize Hotjar
    const siteId = 3732585;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }

  render() {
    return (
      <Router>
        <AuthProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/login" name="Login Page" element={<Login />} />
              <Route path="/register" name="Register Page" element={<Register />} />
              <Route path="/404" name="Page 404" element={<Page404 />} />
              <Route path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </AuthProvider>
        <Analytics />
      </Router>
    );
  }
}

export default App;
