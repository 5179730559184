import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const auth_token_name = process.env.AUTH_TOKEN_NAME
  const api_url = process.env.REACT_APP_BACKEND_URL
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(auth_token_name); // Get token from local storage
    // If token exists, verify it with the server
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      let verify_token_url = api_url + '/api/verify-token';
      axios.get(verify_token_url)
        .then(response => {
          // If the token is valid, set isAuthenticated to true
          if (response.status === 200) {
            console.log("auth set to true");
            setIsAuthenticated(true);
          } else {
            throw new Error('Token validation failed');
          }
        })
        .catch(error => {
          console.error(error);
          // If the token is not valid, remove it from local storage
          localStorage.removeItem(auth_token_name);
          delete axios.defaults.headers.common['Authorization'];
        });
    } else {
      console.log("Not authenticated, waiting for login");
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
